import styled, { css } from "styled-components"
import { breakpoints, media } from "~/styles/breakpoints"

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 80px;
  }

  ${media.tablet} {
    &:not(:last-child) {
      margin-bottom: 120px;
    }
  }
`

const Column = styled.div`
  width: 100%;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    &:last-child {
      margin-top: 70px;
    }
  }

  ${media.tablet} {
    width: 46.5%;
  }

  ${media.desktop} {
    width: 45%;
  }
`

const Intro = styled.div`
  overflow: hidden;
  padding: 150px 0 120px;
  position: relative;

  ${p =>
    p.gradient &&
    css`
      background: linear-gradient(#fff, ${p.gradient});
    `}

  ${p =>
    p.gradientFixed &&
    css`
      background-attachment: fixed;
    `}
    
  ${media.tablet} {
    padding: 220px 0 150px;
  }
`

const IntroText = styled.div`
  ${media.tablet} {
    padding-right: 45%;
  }

  ${media.desktop} {
    padding-right: 35%;

    @media (max-width: 1330px) {
      padding-right: 40%;
    }
  }
`

const IntroImage = styled.img`
  width: 100%;
  margin-top: 60px;
  border-radius: 3px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);

  ${p =>
    p.border &&
    css`
      border: 7px solid #fff;
    `}

  ${media.tablet} {
    width: 600px;
    margin-top: 0;
    position: absolute;
    top: 320px;
    left: calc(50% + 300px);
    transform: rotate(1.5deg);

    @media (max-width: 1910px) {
      width: 550px;
      left: calc(50% + 270px);
    }

    @media (max-width: 1780px) {
      width: 500px;
    }

    @media (max-width: 1640px) {
      width: 450px;
    }

    @media (max-width: 1550px) {
      width: 400px;
      left: calc(50% + 280px);
    }

    @media (max-width: 1440px) {
      width: 350px;
      left: calc(50% + 280px);
    }

    @media (max-width: 1330px) {
      width: 300px;
      left: calc(50% + 250px);
    }

    @media (max-width: 1150px) {
      width: 250px;
      left: calc(50% + 240px);
    }

    @media (max-width: 1023px) {
      left: calc(50% + 90px);
    }
  }
`

export { Columns, Column, Intro, IntroText, IntroImage }
