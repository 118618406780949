import styled from "styled-components"
import { breakpoints, media } from "~/styles/breakpoints"

const PrimaryTitle = styled.h1`
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 40px;
  line-height: 1;
  letter-spacing: -0.005em;
  font-family: ${p => p.theme.font.secondary};

  @media (max-width: ${breakpoints.tablet - 1}px) {
    margin-bottom: 10px;
    padding-bottom: 0.6em;
  }

  ${media.tablet} {
    font-size: 55px;
  }

  ${media.desktop} {
    font-size: 70px;
  }
`

const SecondaryTitle = styled.h2`
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 24px;
  line-height: 1;

  p + & {
    margin-top: 60px;
  }

  ${media.tablet} {
    font-size: 28px;
  }

  ${media.desktop} {
    font-size: 32px;
  }
`

const Lead = styled.div`
  opacity: 0.8;
  margin-bottom: 3em;
  font-size: 20px;

  p:not(:last-child) {
    margin-bottom: 1em;
  }

  ${media.tablet} {
    font-size: 22px;
  }
`

const Paragraph = styled.p`
  margin-bottom: 1em;

  a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    transition: 0.15s;

    &:hover {
      color: #829794;
    }
  }
`

const SmallParagraph = styled.p`
  opacity: 0.7;
  margin-bottom: 1em;
  font-size: 16px;

  .spacer {
    display: inline-block;
    width: 15px;
  }

  + p[class*="SmallParagraph-"] {
    margin-top: -15px;
  }
`

export { PrimaryTitle, SecondaryTitle, Lead, Paragraph, SmallParagraph }
