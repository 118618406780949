import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { media } from "~/styles/breakpoints"
import useScrollListener from "~/hooks/useScrollListener"
import Link from "~/components/Link"

const navigation = [
  {
    title: "Books",
    path: "/books",
  },
  {
    title: "About",
    path: "/about",
  },
  {
    title: "Stories",
    path: "/stories",
  },
  {
    title: "Donate",
    path: "/books",
  },
]

export default ({ pathname }) => {
  const isHomepage = pathname === "/"
  const [isHidden, setHidden] = useState(false)
  const [isCompact, setCompact] = useState(isHomepage)
  const wrapper = useRef()
  const lastScrollPosition = useRef(0)
  const scrolledDown = useRef(false)
  const scrolledUp = useRef(false)
  const delta = 30

  function handleScroll() {
    const position = window.scrollY
    const compactBreakpoint = isHomepage
      ? Math.min(window.innerHeight, window.innerWidth * 0.7)
      : -Infinity
    const hiddenBreakpoint = compactBreakpoint

    if (Math.abs(lastScrollPosition.current - position) > delta) {
      scrolledDown.current = position - delta > lastScrollPosition.current
      scrolledUp.current = !scrolledDown.current
      lastScrollPosition.current = position
    }
    setCompact(position <= compactBreakpoint)
    setHidden(scrolledDown.current && position > hiddenBreakpoint)
  }

  useEffect(() => {
    const activeLink = wrapper.current.querySelector("[aria-current]")
    if (activeLink) {
      activeLink.parentNode.classList.add("-active")
    }
  }, [])

  useScrollListener({
    callback: handleScroll,
  })

  return (
    <Wrapper
      ref={wrapper}
      className={`
        ${isHidden ? "-hidden" : "-visible"}
        ${isCompact ? "-compact" : "-not-compact"}
      `}
    >
      <Logo to="/">
        The Little Lemur
        <br /> Who Spoke
      </Logo>
      <Navigation>
        <NavigationList>
          {navigation.map(({ title, path }, index) => (
            <NavigationItem key={index}>
              <NavigationLink to={path}>
                <NavigationLinkText>{title}</NavigationLinkText>
              </NavigationLink>
            </NavigationItem>
          ))}
        </NavigationList>
      </Navigation>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  padding-left: 20vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  color: #829794;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04), 0 2px 2px rgba(0, 0, 0, 0.04),
    0 4px 4px rgba(0, 0, 0, 0.04), 0 6px 8px rgba(0, 0, 0, 0.04),
    0 8px 16px rgba(0, 0, 0, 0.04);
  transition: 0.15s;

  &.-compact {
    @supports (backdrop-filter: blur(20px)) {
      background: rgba(0, 0, 0, 0.15);
      backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%);
      -webkit-backdrop-filter: blur(20px) saturate(160%) contrast(45%)
        brightness(140%);
      color: #fff;
    }
  }
`

const Logo = styled(Link)`
  display: block;
  width: 20vw;
  padding: 19px 0 19px 10px;
  opacity: 0.7;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-weight: bold;
  font-size: 11px;
  line-height: 0.95;
  font-family: ${p => p.theme.font.secondary};
  transition: 0.15s;

  &:hover {
    opacity: 1;
  }

  ${Wrapper}.-not-compact & {
    background: -webkit-linear-gradient(#01947d, #395489);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  ${media.tablet} {
    padding-left: 20px;
    font-size: 16px;
    line-height: 1;
  }

  ${media.desktop} {
    padding-left: ${p => p.theme.sidePadding.desktop}px;
  }
`

const Navigation = styled.nav``

const NavigationList = styled.ul`
  display: flex;
`

const NavigationItem = styled.li`
  width: 25%;
  height: 50px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  line-height: 50px;
  letter-spacing: 0.05em;

  ${media.tablet} {
    height: 60px;
    line-height: 60px;
    font-size: 15px;
  }

  ${media.desktop} {
    height: 70px;
    line-height: 70px;
  }
`

const NavigationLink = styled(Link)`
  display: block;
  position: relative;
  transition: 0.15s;

  &::before {
    content: "";
    width: 1px;
    position: absolute;
    top: 15px;
    bottom: 15px;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    transition: 0.15s;

    ${NavigationItem}.-active + li &,
    ${NavigationItem}:hover + li & {
      opacity: 0;
    }

    @supports (backdrop-filter: blur(20px)) {
      ${Wrapper}.-compact & {
        background: rgba(255, 255, 255, 0.17);
      }
    }
  }

  &::after {
    content: "";
    opacity: 0;
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    background: #829794;
    border-radius: 5px;
    transition: 0.15s;

    @supports (backdrop-filter: blur(20px)) {
      ${Wrapper}.-compact & {
        background: #fff;
      }
    }
  }

  &[aria-current] {
    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 0.2;

      ${NavigationItem}:last-child & {
        visibility: hidden;
      }
    }
  }

  &:not([aria-current]):hover {
    color: #fff;

    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 1;
    }

    @supports (backdrop-filter: blur(20px)) {
      ${Wrapper}.-compact & {
        color: #829794;
      }
    }
  }
`

const NavigationLinkText = styled.span`
  position: relative;
  z-index: 1;
`
